import {
  AgreementToSign,
  AgreementsProps,
} from "@src/app/onboardingStripe/components/AgreementToSign";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useGetAgreements } from "@src/appV2/Agreements/api/useGetAgreements";
import { AgreementKind } from "@src/appV2/Agreements/types";
import { getNewestSignedAgreementFromResponse } from "@src/appV2/Agreements/utils/getNewestSignedAgreementFromResponse";
import { PdfViewerProvider } from "@src/appV2/PdfViewer/context";

type AgreementContainerProps = Pick<
  AgreementsProps,
  "isLoading" | "onAcceptAgreement" | "disableBackButton"
>;

export function AgreementContainer(props: AgreementContainerProps) {
  const agent = useDefinedAgent();

  const workerId = agent?.userId ?? "";

  const {
    data: agreements,
    isLoading: isLoadingAgreements,
    isError: isErrorAgreements,
    isSuccess: isSuccessAgreements,
  } = useGetAgreements(
    {
      workerId,
      kind: [AgreementKind.INDEPENDENT_CONTRACTOR],
    },
    { refetchOnWindowFocus: false }
  );

  const agreementToSign = isSuccessAgreements
    ? getNewestSignedAgreementFromResponse(agreements)
    : undefined;

  const pdfUrl = agreementToSign?.attributes.downloadUrl;

  return (
    <PdfViewerProvider fileName="clipboard_health_contract.pdf" pdfUrl={pdfUrl}>
      <AgreementToSign
        isLoading={props.isLoading}
        onAcceptAgreement={props.onAcceptAgreement}
        disableBackButton={props.disableBackButton}
        isLoadingAgreements={isLoadingAgreements}
        isErrorAgreements={isErrorAgreements}
        isSuccessAgreements={isSuccessAgreements}
        agreementToSign={agreementToSign}
      />
    </PdfViewerProvider>
  );
}
