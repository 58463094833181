import { LoadingButton, Span } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { CONTRACTOR_AGREEMENT_VERSIONS } from "@lib/interface";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from "@mui/material";
import { ReadAgreementError } from "@src/app/onboardingStripe/components/ErrorMessages/ReadAgreementError";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { NoAgreementTemplateFileAvailable } from "@src/appV2/Agreements/NoAgreementTemplateFileAvailable";
import { Agreement, SignedAgreement } from "@src/appV2/Agreements/types";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, BackButtonLink } from "@src/appV2/lib";
import { Banner } from "@src/appV2/lib/Alert";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { APP_BAR_HEIGHT } from "@src/appV2/lib/AppBarHeader/AppBarHeader";
import { usePdfViewerContext } from "@src/appV2/PdfViewer/context";
import { PdfViewer } from "@src/appV2/PdfViewer/PdfViewer";
import { PdfViewerLoadingSkeleton } from "@src/appV2/PdfViewer/PdfViewerLoadingSkeleton";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { TabRouterPath } from "../../routing/constant/tabRoute";

export interface AgreementsProps {
  onAcceptAgreement: (agreementVersion: CONTRACTOR_AGREEMENT_VERSIONS) => void;
  isLoading: boolean;
  disableBackButton?: boolean;
  isLoadingAgreements: boolean;
  isErrorAgreements: boolean;
  isSuccessAgreements: boolean;
  agreementToSign: Agreement | SignedAgreement | undefined;
}

const signAgreementFormSchema = z.object({
  confirmation: z.boolean().refine((value) => value, { message: "You must confirm to proceed." }),
  agreementScrolled: z.boolean().refine((value) => value, {
    message: "Please read the full agreement and scroll to the bottom.",
  }),
});

/**
 * This is a new component for signing the agreement. It is going through a big changes and current state is not final
 * Upcoming changes are:
 * - Using different endpoint to sign agreement
 */
export function AgreementToSign(props: AgreementsProps) {
  const {
    onAcceptAgreement,
    isLoading,
    disableBackButton,
    isLoadingAgreements,
    isErrorAgreements,
    isSuccessAgreements,
    agreementToSign,
  } = props;
  const worker = useDefinedWorker();

  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const formMethods = useForm({
    defaultValues: {
      confirmation: false,
      agreementScrolled: false,
    },
    mode: "all",
    resolver: zodResolver(signAgreementFormSchema),
  });

  const {
    formState: { errors, isValid: formIsValid },
    register,
    handleSubmit,
    setValue,
    trigger,
  } = formMethods;

  const contactSupportLinks = useSupportLinks("SIGN_IC_AGREEMENT");
  const { currentPage, numberOfPages, isSupportedPlatform } = usePdfViewerContext();

  useLogEffect(isDefined(agreementToSign) ? APP_V2_USER_EVENTS.SIGN_AGREEMENT_VIEWED : undefined, {
    hcpId: worker.userId,
    name: worker.name,
    agreementVersion: agreementToSign?.attributes.version,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (currentPage === numberOfPages || isSupportedPlatform === false) {
      setValue("agreementScrolled", true, { shouldValidate: true });
      setShowErrorBanner(false);
    }

    if (isSupportedPlatform === false) {
      logEvent(APP_V2_APP_EVENTS.PDF_PLATFORM_UNSUPPORTED_ONBOARDING);
    }
  }, [currentPage, numberOfPages, isSupportedPlatform, setValue]);

  // TODO: Neither PageWithHeader is suitable for this purpose (no way to utilize flex layout and limit pdf viewer height because of
  // structure that can't be modified or amended by props). AppBarHeader isn't fitting here as well because it has dark background with no option to change
  // Also FullscreenPageLayout does not work because of gradient background and no customization options
  // Adding custom Stack as container here until we figure out proper page wrappers.
  // Since onboarding revamping is coming, this might be a good thing to tackle first
  return (
    <Stack
      spacing={0}
      padding={0}
      direction="column"
      sx={{
        // This container needs to fill the whole screen. Using 100vh includes the height of the browser's address bar
        // on mobile Chrome making the page scrollable, which we don't want. Setting position: absolute and inset: 0
        // makes the container cover the full screen, ignoring the browser's UI elements.
        // This is how IonPage was styled as well
        position: "absolute",
        inset: 0,
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      }}
    >
      {!disableBackButton && (
        <Stack direction="row" paddingLeft={2} height={APP_BAR_HEIGHT}>
          <BackButtonLink
            defaultBackTo={
              worker.onboardingFlags?.isSignupCompleted
                ? TabRouterPath.OPEN_SHIFTS
                : OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW
            }
          />
        </Stack>
      )}

      {showErrorBanner && (
        <Banner severity="error">
          <ReadAgreementError userId={worker.userId} contactSupportLinks={contactSupportLinks} />
        </Banner>
      )}

      <Box sx={{ flex: "1 1 auto", overflow: "auto" }}>
        {isLoadingAgreements && <PdfViewerLoadingSkeleton />}
        {isErrorAgreements && <NoAgreementTemplateFileAvailable />}
        {isSuccessAgreements && <PdfViewer />}
      </Box>

      <Stack padding={2} spacing={1} sx={{ flex: "0 0 auto" }} margin="auto">
        <FormControl
          component="fieldset"
          error={Boolean(errors.agreementScrolled)}
          variant="standard"
        >
          <input type="hidden" {...register("agreementScrolled")} />

          <FormHelperText>{errors.agreementScrolled?.message}</FormHelperText>
        </FormControl>

        <FormControl component="fieldset" error={Boolean(errors.confirmation)} variant="standard">
          <FormControlLabel
            control={<Checkbox {...register("confirmation")} sx={{ alignSelf: "flex-start" }} />}
            label={
              <Span>
                By checking this box, you are signing this agreement electronically and agreeing to
                have your electronic signature in the name of <b>{worker.name}</b> added to the
                agreement
              </Span>
            }
          />
          <FormHelperText>{errors.confirmation?.message}</FormHelperText>
        </FormControl>

        <LoadingButton
          variant={formIsValid ? "contained" : "outlined"}
          disabled={isLoadingAgreements}
          data-testid="continue-button"
          isLoading={isLoading}
          onClick={handleSubmit(
            () => {
              onAcceptAgreement?.(
                // TODO: This concatenation will be replaced when we use new endpoint for signing the agreement
                `V${agreementToSign!.attributes.version}` as CONTRACTOR_AGREEMENT_VERSIONS
              );
            },
            (data) => {
              setShowErrorBanner(Boolean(data.agreementScrolled));
            }
          )}
          fullWidth
          size="small"
          sx={{ lineHeight: "1.5rem" }}
        >
          I have read and agree to this Independent Contractor Agreement
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
