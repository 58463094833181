import { PaymentServiceAccountStatus } from "@src/lib/interface";

import { ShouldBeOnboardedStatuses } from "./model";
import { useSession } from "../store/helperHooks";

export function useShouldBeOnboarded() {
  const { agent } = useSession();

  const isAgentPendingInPaymentService =
    agent?.canBeOnboardedInStripe &&
    ShouldBeOnboardedStatuses.includes(
      agent?.paymentAccountInfo?.status as PaymentServiceAccountStatus
    );

  return !!isAgentPendingInPaymentService;
}
