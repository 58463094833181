import "./styles.scss";
import { Preferences } from "@capacitor/preferences";
import { AgreementContainer } from "@src/app/onboardingStripe/components/agreementContainer";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { USER_EVENTS } from "@src/constants";
import { Agent } from "@src/lib/interface";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { updateAgentProfileState } from "./actions";
import { createPaymentServiceAccount, signedContractorAgreement } from "./api";
import { OnBoardingComponentProps } from "../onboarding/components/model";
import { useDefinedAgent } from "../store/helperHooks";
import { LocalStorage, reloadAgentProfile } from "../store/session";

export const OnboardToStripe: FC<OnBoardingComponentProps> = ({
  nextStagePath,
  disableBackButton,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const agent = useDefinedAgent();
  const history = useHistory<{ redirectTo: "" }>();
  const { showErrorToast } = useToast();
  const updateWorkerCache = useUpdateWorkerCache();

  useEffect(() => {
    const hasWorkerName = !!agent.name?.trim();
    setLoading(!hasWorkerName);
    if (hasWorkerName) {
      return;
    }
    dispatch(reloadAgentProfile());
  }, [dispatch, agent.name]);

  const handleAccountCreate = useCallback(async () => {
    const splitName = agent?.name?.split(" ") ?? [];
    let firstName = "";
    let lastName = "";
    if (splitName.length > 0) {
      firstName = splitName[0] ?? "";
      lastName = splitName[splitName?.length - 1] ?? "";
    }

    const hcpAddress = {
      line1: `${agent?.address?.streetNumber || ""} ${agent?.address?.streetName ?? ""}`,
      city: agent?.address?.city,
      state: agent?.address?.stateCode,
      postalCode: agent?.address?.postalCode,
    };
    return await createPaymentServiceAccount({
      agentId: agent?.userId,
      email: agent?.email as string,
      msa: agent?.address?.metropolitanStatisticalArea,
      individual: {
        firstName: agent?.firstName || firstName,
        lastName: agent?.lastName || lastName,
        phone: agent?.phone,
        birthday: agent?.dob,
        address: hcpAddress,
      },
    });
  }, [agent]);

  const handleAgreementAcceptance = useCallback(
    async (agreementVersion) => {
      try {
        setLoading(true);

        const updatedAgent = await signedContractorAgreement(
          agent.userId as string,
          agreementVersion,
          agent?.name
        );
        logEvent(USER_EVENTS.SIGNED_CONTRACT, {
          hcpID: agent?.userId as string,
          email: agent?.email,
          signature: agent?.name,
          agreementVersion,
        });

        const { signedContractorAgreements } = updatedAgent;

        if (!agent?.paymentAccountInfo) {
          try {
            const paymentAccountInfo = await handleAccountCreate();
            updateWorkerCache({
              signedContractorAgreements,
              paymentAccountInfo,
            });
            dispatch(
              updateAgentProfileState({
                signedContractorAgreements,
                paymentAccountInfo,
              })
            );
          } catch (error) {
            const errorMessage = (error.response as any)?.body?.message;
            // Stripe may indicate error passed client-side validation, like invalid address, postal code, etc.
            const isStripeError = error.status === 400 && errorMessage;
            const defaultMessage =
              "You have accepted the Independent Contractor Agreement successfully. However, we are having a technical issue accessing or creating your Stripe account.  Please contact Customer Support.";
            const stripeErrorMessage = `You have accepted the Independent Contractor Agreement successfully. However, your stripe account wasn't created. Error: ${errorMessage}. Please contact Customer Support.`;
            showErrorToast(isStripeError ? stripeErrorMessage : defaultMessage);
            logError(APP_V2_APP_EVENTS.STRIPE_ONBOARDING_ERROR, {
              error,
              metadata: {
                message: "Error occurred setting up stripe account on IC Agreement signing",
                userId: agent?.userId,
              },
            });
            return;
          }
        }
        updateWorkerCache({
          signedContractorAgreements,
        });
        dispatch(
          updateAgentProfileState({
            signedContractorAgreements,
          })
        );

        const { value } = await Preferences.get({ key: LocalStorage.AGENT });
        if (value) {
          const agent = JSON.parse(value) as Agent;
          agent.signedContractorAgreements = signedContractorAgreements;
          await Preferences.set({
            key: LocalStorage.AGENT,
            value: JSON.stringify(agent),
          });
        }

        if (nextStagePath) {
          history.push(nextStagePath);
          return;
        }
        const { redirectTo } = history?.location?.state || {};
        if (redirectTo) {
          history.replace(redirectTo);
        }
      } catch (error) {
        showErrorToast(
          "Sorry we are experiencing technical problems. Please contact support, or try again later."
        );
        logError(APP_V2_APP_EVENTS.STRIPE_ONBOARDING_ERROR, {
          error,
          metadata: {
            message: "Error occurred signing IC Agreement",
            userId: agent?.userId,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      agent,
      dispatch,
      handleAccountCreate,
      history,
      nextStagePath,
      showErrorToast,
      updateWorkerCache,
    ]
  );

  return (
    <div className="cbhAppV1">
      <AgreementContainer
        onAcceptAgreement={handleAgreementAcceptance}
        isLoading={loading}
        disableBackButton={disableBackButton}
      />
    </div>
  );
};
